<template>
	<div class="moderesources" style="background: #d1e0f9;padding-top: 50px;padding-bottom: 50px;">
		<el-row style="width: 1100px;margin: 0 auto;">
			<el-col class="" :span="24" style="margin-bottom: 20px;font-size: 14px;color: #999;">
				当前位置：<span>{{mianbaoxie1}}</span>
			</el-col>
			<el-col :span="18" style="background: #fff;padding: 15px;">
				<el-col :span="24">
					<el-col class="" :span="24" style="margin-bottom: 30px;">
						<el-col style="">
							<div id="domId" v-show="show1"></div>
						</el-col>
					</el-col>
					<el-col class="" :span="24" style="border-top:5px solid #d1e0f9;padding-top: 20px;padding-bottom: 20px;">
						<el-col style="">
							<p style="font-size: 16px;line-height: 40px;">概述：</p>
							<span class="rabbit-font">{{parameter.introduce}}</span>
						</el-col>
					</el-col>
					<el-col class="" :span="24" style="border-top:5px solid #d1e0f9;padding-top: 30px;margin-bottom: 20px;line-height: 40px;">
						<el-col style="font-size: 20px;" :span="24">注意事项购买前请仔细查看以下说明:</el-col>
						<el-col style="font-size: 20px;" :span="24">模型动画说明:</el-col>
						<el-col style="" :span="24">1、购买需支付相应金币，购买后可永久使用，如遇模型优化修改也可继续享受免费使用；</el-col>
						<el-col style="" :span="24">2、模型购买后可在BIM-FILM中【我的模型】-【已购买模型】中下载使用；</el-col>
						<el-col style="" :span="24">3、模型库中的模型购买后暂不支持下载源文件，只能在BIM-FILM中使用；</el-col>
						<el-col style="" :span="24">4、动画库中的动画购买后可下载bfm源文件在BIM-FILM中使用；</el-col>
						<el-col style="" :span="24">5、bfm格式的是BIMFILM-v1.0,bfm2格式的是BIMFILM-v2.0两者不兼容，购买前仔细查看软件版本信息；</el-col>
						<el-col style="" :span="24">6、模型动画购买后暂不支持退换，除非购买的资源与所展示的不一致。</el-col>
						<el-col style="" :span="24">版权说明：</el-col>
						<el-col style="" :span="24">在本站购买的素材资源只拥有使用权，著作权归作者及BIM秀秀网所有。</el-col>
						<el-col style="" :span="24">未经合法授权，会员不得以任何形式发布、传播、复制、转售该资源。</el-col>
					</el-col>
				</el-col>
			</el-col>
			<el-col :span="6" style="padding-left: 20px;">
				<el-col :span="24" style="background: #fff;padding-bottom: 50px;">
					<el-col class="" v-show="!is_zigm" :span="24" style="text-align: center;line-height: 50px;">
						购买需<span style="color:#ffa800;font-weight: bold;font-size: 20px;">{{parameter.goldcoin}}</span>金币
					</el-col>
					<el-col class="" v-show="is_zigm" :span="24" style="text-align: center;line-height: 50px;">
						您已购买
					</el-col>
					<el-col class="" v-show="!is_zigm" :span="24" style="text-align: center;">
						<el-col class="" :span="13">
							<el-button type="primary" @click="Shopping(parameter.id)">加入购物车</el-button>
						</el-col>
						<el-col class="" :span="7">
							<el-button type="primary" @click="Collection(parameter.id)">直接购买</el-button>
						</el-col>
					</el-col>
					<el-col class="" :span="24" v-show="is_zigm" style="text-align: center;">
						<p v-show="!is_animation">请前去BIMFILM软件使用。</p>
						<el-button type="primary" v-show="is_animation" @click="download()">下载</el-button>
					</el-col>
					<el-col class="" :span="24" style="height: 50px;line-height: 50px;margin-top: 20px;background: #efefef;text-align: center;font-size: 14px;color: #999;border-top: 1px dashed #999;border-bottom: 1px dashed #999;">
						<span class="mr-1">下载 {{parameter.download_number}} | </span>
						<span class="mr-1">观看 {{parameter.look_number}} </span>
						<!--<span class="mr-1">收藏 0</span>-->
					</el-col>
					<el-col class="" :span="24" style="line-height: 40px;text-align: left;padding: 15px;font-size: 14px;border-bottom: 1px dashed #999;">
						<h4>{{parameter.name}}</h4>
						<el-col class="" :span="24">作者：{{parameter.author}}</el-col>
						<el-col class="" :span="24">ID：{{parameter.id}} </el-col>
						<el-col class="" :span="24">软件版本：{{parameter.soft_name}}-{{parameter.ver_name}}</el-col>
						<el-col class="" :span="24">素材大小：{{(parameter.file_size / 1024/1024).toFixed(1)}}MB</el-col>
						<el-col class="" :span="24">更新时间：{{parameter.update_time}}</el-col>
					</el-col>
					<el-col class="" :span="24" style="line-height: 40px;text-align: left;padding: 15px;font-size: 14px;border-bottom: 1px dashed #999;">
						<div class="d-flex flex-wrap justify-content-start" style="font-size: 15px;color:#666;">
							<el-col class="" :span="10" v-show="parameter.bind!=0">绑定：<img src="https://res.bimshows.cn/res/rabbit/image/main/success.png"
								 alt=""> </el-col>
							<el-col class="" :span="10" v-show="parameter.map!=0">贴图：<img src="https://res.bimshows.cn/res/rabbit/image/main/success.png"
								 alt=""></el-col>
							<el-col class="" :span="10" v-show="parameter.animation!=0">动画：<img src="https://res.bimshows.cn/res/rabbit/image/main/success.png"
								 alt=""></el-col>
							<el-col class="" :span="10" v-show="parameter.plugin!=0">插件：<img src="https://res.bimshows.cn/res/rabbit/image/main/success.png"
								 alt=""></el-col>
							<el-col class="" :span="10" v-show="parameter.mat!=0">材质：<img src="https://res.bimshows.cn/res/rabbit/image/main/success.png"
								 alt=""></el-col>
							<el-col class="" :span="10" v-show="parameter.dent!=0">塌陷：<img src="https://res.bimshows.cn/res/rabbit/image/main/success.png"
								 alt=""></el-col>
						</div>
					</el-col>
					<el-col class="" :span="24" style="line-height: 40px;text-align: left;padding: 15px;font-size: 14px;">
						<el-col class="" :span="24" v-show="parameter.point!=0">点：{{parameter.point}}</el-col>
						<el-col class="" :span="24" v-show="parameter.side!=0">面：{{parameter.side}}</el-col>
					</el-col>
				</el-col>
			</el-col>
		</el-row>
		<el-dialog title="提示" :visible.sync="download_dialogVisible" width="60%">
			<template>
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="file_mp4" label="mp4">
					</el-table-column>
					<el-table-column prop="file_origin" :label="filename">
					</el-table-column>
					<el-table-column>
						<template slot-scope="scope">
							<el-row>
								<el-col>
									<el-button type="primary" size='mini' @click='download_file(scope.row.file_mp4,scope.row)' :disabled='scope.row.file_mp4==null?true:false'>下载mp4</el-button>
									<el-button type="primary" size='mini' @click='download_file(scope.row.file_origin,scope.row)' :disabled='scope.row.file_origin==null?true:false'>下载{{filename}}</el-button>
								</el-col>
							</el-row>
						</template>
					</el-table-column>
				</el-table>
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button @click="download_dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="download">确 定</el-button>
			</span>
		</el-dialog>
		<!--<login  v-show="showlogin" @closeDialog="closeDialog"></login>-->
	</div>
</template>
<script>
	import {
		requestDetails,
		requestAddpush,
		requestaccount_collect_add,
		requestResource_purchase,
		requestdirectory_add,
		requestAccount_cart,
		resource_download,
		requestAccount_crumbs,
preview_script
	} from "../api/request";
	export default {
		components: {
		},
		data() {
			return {
				radio: '1',
				parameter: {},
				labelPosition: '2D',
				show: true,
				video_show: true,
				img_show: true,
				show1: false,
				video1_show: false,
				img1_show: true,
				viewer3D: '',
				app: '',
				viewAdded: false,
				isMapLoaded: false,
				loaderConfig: null,
				viewer: null,
				is_click: 0,
				//select选择框
				options: [],
				//选择框绑定
				value: '',
				//资源数据
				resource_data: '',
				is_zigm: false,
				add_ProjectList_input: '',
				Child_list: '',
				current_select_level1_item: null,
				mianbaoxie1: "",
				is_animation: null,
				download_dialogVisible: false,
				tableData: [],
				rotate: true,
				imgurl: require('../assets/img/rotate.png'),
				filename: null
			};
		},
		methods: {
			//下载
			download() {
				let aaa = this.tableData[0].file_origin.split('.')[1];
				this.filename = aaa;
				this.download_dialogVisible = true;
			},
			download_file(file, file_list) {
				let _this = this;
				let aaa = file.split('.')[1];
				let bbb = '.' + aaa;
				let data = {
					resource_id: file_list.id,
					md5: file.split('.')[0],
				};
				resource_download(data).then(res => {
					this.$message({
						message: '下载中，请稍等',
						type: 'success'
					});
					if (aaa == 'mp4') {
						_this.common.downloadfile(res.ret[0], file_list.name, bbb);
					} else {
						_this.common.modalnormaldownload(file_list.name, res.ret[0])
					}
				})
			},
			//获取当前资源详情并赋值
			Details: function() {
				let id = this.common.GetRequest().id,
					_this = this;
				let data = {
					id: id
				};
				let data1 = {
					resource_id: id
				};


				requestDetails(data).then(function(e) {
					if (e.ret.pack_type == 6) {
						_this.is_animation = true;
						_this.tableData.push(e.ret)
					} else {
						_this.is_animation = false;
					}

					if (e.ret.file_image != undefined && e.ret.file_image != null && e.ret.file_image != "") {
						e.ret.file_image = e.ret.file_image.split(",")
					}
					_this.parameter = e.ret;
					if (e.ret.offlinepackurl == null) {
						_this.show = false
					} else {
						_this.show1 = false
					}
					if (e.ret.file_mp4 == null || e.ret.file_mp4 == '') {
						_this.video_show = false
						_this.video1_show = false
					}
					console.log(e.ret)
					if (_this.Account.GetStorage().vip_type == 1 && e.ret.type_id == 1) {
						_this.is_zigm = true
					} else {
						requestResource_purchase(data1).then(function(e) {
							if (e.code == 0) {
								_this.is_zigm = true
							} else {
								_this.is_zigm = false
							}
						});
					}
				});
				console.log(_this.parameter)

			},
			onrotate() {
				this.rotate = !this.rotate;
				if (this.rotate) {
					this.viewer.startAutoRotate(-2);
				} else {
					this.viewer.startAutoRotate(0);
				}
			},
			//加购
			Shopping(e) {
				let _this = this;
				requestAddpush(e).then(function(res) {
					if (res.code == 0) {
						_this.$message({
							message: '加入购物车成功',
							type: 'success'
						});
					} else if (res.code == 5012) {
						_this.$confirm('BIM模型库中的企业CI库资源与BIM动画库中资源非会员不可购买！是否前往购买会员？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							_this.$router.push("/VipPrivilge")
						}).catch(() => {
							_this.$message({
								type: 'info',
								message: '已取消前往购买'
							});
						});
					}
				})
			},
			//购买
			Collection(e) {
				if (this.Account.GetSession() == null) {
					this.$parent.logo_open();
					return
				};
				let obj = {
					resource_id: this.parameter.id,
					goldcoin: this.parameter.goldcoin,
					owner_id: this.parameter.account_id,
				}
				let data_this = this,
					ayy_ay = [];
				ayy_ay.push(obj);
				this.$confirm('购买前请仔细查看模型动画注意事项,是否购买此资源?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					requestAccount_cart(ayy_ay).then(res => {
						if (res.code == 0) {
							data_this.$message({
								message: '已成功购买！',
								type: 'success'
							});
							data_this.common.coin_calculation(1, data_this.parameter.goldcoin)
							setTimeout(function() {
								data_this.$router.go(0)
							}, 1000)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消购买'
					});
				});

			},
			//面包屑
			mianbaoxie: function() {
				let info = this.$router.currentRoute.query.id;
				requestAccount_crumbs(info).then(res => {
					console.log(res)
					let mianbao = "";
					for (var i = 0; i < res.ret.length; i++) {
						if (res.ret.length != i + 1) {
							mianbao += res.ret[i].name + ">"
						} else {
							mianbao += res.ret[i].name
						}
					}
					this.mianbaoxie1 = mianbao


				})

			}
		},
		watch: {
			labelPosition: function(newVal, oldVal) {
				let _this = this;
				//单选监听实时获取
				if (newVal == "2D") {
					this.show1 = false;
					this.video1_show = false;
					this.img1_show = true
				} else if (newVal == "3D") {
					this.show1 = true;
					this.video1_show = false;
					this.img1_show = false;
					if (this.is_click == 0) {
						_this.BimfaceLoaderConfig.dataEnvType = BimfaceEnvOption.Local;
						_this.BimfaceLoaderConfig.sdkPath = _this.parameter.jssdk + '/jssdk';
						_this.BimfaceLoaderConfig.path = _this.parameter.offlinepackurl + '/viewToken.json';
						BimfaceSDKLoader.load(_this.BimfaceLoaderConfig, _this.onSDKLoadSucceeded, _this.onSDKLoadFailed);
					}
					this.is_click = 1
				} else if (newVal == 'shipin') {
					this.show1 = false;
					this.video1_show = true;
					this.img1_show = false;
				}
			},


		},
		mounted() {
			this.mianbaoxie();
			this.Details();
			console.log('--------------------------------1-----------------------------------------')
			const s = document.createElement('script');
			s.type = 'text/javascript';
			s.src = 'https://res.bimshows.cn/bimface/jssdk/BimfaceSDKLoader@latest-release.js';
			document.body.appendChild(s);
			let data={
				object:''
			}
			preview_script(data).then(async res=>{
				
				let demo = aliyun.config({
					mount: document.querySelector('#domId'),
					url:res.ret.PreviewURL //设置文档预览URL地址。
				  })
				  //设置AccessToken。
				  demo.setToken({token: res.ret.AccessToken})
			})
		},
	};
</script>
<style scoped>
	@import "../style/common.css";

	.shaixuan {
		background: #fff;
	}

	.shaixuan>div {
		height: 50px;
		line-height: 50px;
		border-bottom: 1px dashed #ccc;
	}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #e6e6e6;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #e6e6e6;
	}

	.buttons {
		font-size: 0;
	}

	.button {
		margin: 5px 0 5px 5px;
		width: 100px;
		height: 30px;
		border-radius: 3px;
		border: none;
		background: #32D3A6;
		color: #FFFFFF;
	}

	.main {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
	}

	#domId {
		flex: 1;
		height: 500px;
	}
</style>
